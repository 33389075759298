import { Box, Collapse, Drawer, List, ListItem, ListItemText, Toolbar, alpha, useMediaQuery, useTheme } from '@mui/material'
import * as React from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ActiveRouteIdState, ExpandedMenuState, NavBarOpenState } from '../../recoil/navigation/Atom'
import LogoFullHorizontal from '../../assets/app-logo/LogoFullHorizontalColor'
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material'
import ROUTES from './Routes'
import * as _ from 'lodash'
import { ApplicationUserState } from '../../recoil/user/Atom'
import SvgLogoFullHorizontalWhite from '../../assets/app-logo/LogoFullHorizontalWhite'


const TEXT_FONT_WEIGHT = 400
const TEXT_FONT_WEIGHT_ACTIVE = 700
const TEXT_FONT_WEIGHT_HEADER = 400
const TEXT_FONT_SIZE = '1rem'
const NESTING_LEVEL_PADDING_MULTIPLIER = 2.5

const GetDrawerWidth = (matches) => {

    const breakpoints = Object.keys(matches)
    const values = Object.values(matches)
    const idx = values.findIndex(e => e === true)

    const matchedBreakPoint = breakpoints[idx]

    switch(matchedBreakPoint){
        case 'xs' : return '80vw';
        case 'sm' : return '50vw';
        case 'md' : return '35vw';
        case 'lg' : return '22vw';
        case 'xl' : return '18vw';
        default: return '20vw'
    }

}

const getActiveRouteId = (routes, pathName) => {

    let activeRouteId = null
    const ScanRoutes = (data) => {

        for(let i=0;i<data.length;i++){

            if(data[i].link === pathName){
                activeRouteId = data[i].routeId
                return
            } else if(data[i].nested){
                ScanRoutes(data[i].nestedRoutes)
            }

        }

    }

    ScanRoutes(routes)
    return activeRouteId

}

const ListItemLink = (props) => {

    const theme = useTheme()
    const [drawerOpen, setDrawerOpen] = useRecoilState(NavBarOpenState)
    const { primary, to, activeRouteId, routeId, external } = props
    
    const renderLink = React.useMemo(
        () => React.forwardRef((itemsProp, ref) => {

            if(external){
                return (
                    <a href={to} target="_blank" {...itemsProp} innerRef={ref}></a>
                )
            } else {
                return (
                    <RouterLink to={to} {...(external ? { target: "_blank"}: {})} {...itemsProp} innerRef={ref} />
                )
            }

        })
    ,[to])

    return (
        <ListItem key={routeId} onClick={() => setDrawerOpen(false)} dense sx={{borderRadius: 0, my: 0, mx:0, ['&:hover']: { color: `${theme.palette.primary.main}`, background: `${alpha(theme.palette.primary.main, 0.10)}`, borderRadius: theme.shape.borderRadius}, ...(routeId === activeRouteId ? {borderRadius: theme.shape.borderRadius} : {})}} selected={routeId === activeRouteId} button component={renderLink}>
            <ListItemText insert={false} primaryTypographyProps={{...(activeRouteId === routeId ? { color: 'primary', fontWeight: TEXT_FONT_WEIGHT_ACTIVE} : { color: theme.palette.mode === 'light' ? `#bf360c` : `#ffffff`, fontWeight: TEXT_FONT_WEIGHT}), fontSize: TEXT_FONT_SIZE, pl: props.nestingLevel * NESTING_LEVEL_PADDING_MULTIPLIER }} primary={primary} />
        </ListItem>
    )

}

const RouteHeader = (props) => {

    const theme = useTheme()

    return (
        <ListItem dense key={props.routeId}>
            <ListItemText primaryTypographyProps={{color: theme.palette.mode === 'light' ? `#bf360c` : `#ffffff`,fontWeight: TEXT_FONT_WEIGHT_HEADER, fontSize: TEXT_FONT_SIZE, color: 'textPrimary', textTransform: 'uppercase', pl: props.nestingLevel * NESTING_LEVEL_PADDING_MULTIPLIER}} primary={props.title} />
        </ListItem>
    )

}

const NestedRoute = (props) => {

    const theme = useTheme()
    const activeRouteId = useRecoilValue(ActiveRouteIdState)
    const userState = useRecoilValue(ApplicationUserState)

    const [expandOpen, setExpandOpen] = useRecoilState(ExpandedMenuState)
    const handleExpand = (title) => {

        setExpandOpen(prevState => ({
            ...prevState,
            [title]: !prevState[title]
        }))

    }

    return (
        <React.Fragment>
        <ListItem dense sx={{borderRadius: 0, my: 0, [`&:hover`]: {color: `${theme.palette.primary.main}`, background: `${alpha(theme.palette.primary.main,0.10)}`}, borderRadius: theme.shape.borderRadius}} selected={activeRouteId === props.routeId} button onClick={() => handleExpand(props.title)} key={props.routeId}>
            <ListItemText primaryTypographyProps={{color: theme.palette.mode === 'light' ? `#b72e04` : `#ffffff`,fontWeight: TEXT_FONT_WEIGHT_HEADER, fontSize: TEXT_FONT_SIZE, pl: props.nestingLevel * NESTING_LEVEL_PADDING_MULTIPLIER, [`&:hover`]: { fontSize: TEXT_FONT_WEIGHT_HEADER}}} primary={props.title} />
            { expandOpen[props.title] ? <ExpandLessRounded sx={{color: `${theme.palette.mode === 'light' ? theme.palette.primary.dark : '#fff'}`}} /> : <ExpandMoreRounded sx={{color: `${theme.palette.mode === 'light' ? theme.palette.primary.dark : '#fff'}`}} />}
        </ListItem>
        <Collapse in={expandOpen[props.title]} timeout="auto" unmountOnExit>
            <List disablePadding dense>
                {
                    props.nestedRoutes.filter(route => route.sideNav).filter(route => route.roles.some(e => userState.role.includes(e))).map(route => {
                        if(route.nested){
                            return (
                                <NestedRoute activeRouteId={props.activeRouteId} nestingLevel={props.nestingLevel+1} {...route} />
                            )
                        }
                        if(!route.header){
                            return (
                                <ListItemLink nestingLevel={props.nestingLevel+1} activeRouteId={props.activeRouteId} routeId={route.routeId} nested={true} primary={route.title} to={route.link} key={route.routeId} external={route.external} />
                            )
                        }
                        if(route.header){
                            return (
                                <RouteHeader nestingLevel={props.nestingLevel} {...route} />
                            )
                        }
                    }) 
                }
            </List>
        </Collapse>
    </React.Fragment>
    )

}

const SideNav = (props) => {

    const theme = useTheme()
    const location = useLocation()
    let navigate = useNavigate()
    const [sideNavRoutes, setSideNavRoutes] = React.useState(_.cloneDeep(ROUTES))
    const userState = useRecoilValue(ApplicationUserState)

    const widthMatch = {
        xs: useMediaQuery(theme.breakpoints.only('xs')),
        sm: useMediaQuery(theme.breakpoints.only('sm')),
        md: useMediaQuery(theme.breakpoints.only('md')),
        lg: useMediaQuery(theme.breakpoints.only('lg')),
        xl: useMediaQuery(theme.breakpoints.only('xl'))
    }

    const DRAWER_WIDTH = GetDrawerWidth(widthMatch)

    const [drawerOpen ,setDrawerOpen] = useRecoilState(NavBarOpenState)

    const [expandOpen, setExpandOpen] = useRecoilState(ExpandedMenuState)
    const [activeRouteId, setActiveRouteId] = useRecoilState(ActiveRouteIdState)

    const handleDrawerClose = () => {

        setDrawerOpen(false)

    }

    React.useEffect(() => {

        const routeId = getActiveRouteId(sideNavRoutes, location.pathname)
        setActiveRouteId(routeId)

        const rootRouteId = parseInt(routeId / 10)

        if(rootRouteId > 0){

            const routeObject = sideNavRoutes.filter(route => route.routeId === rootRouteId)[0]
            if(routeObject != null){

                setExpandOpen(expandOpen => ({...expandOpen, [routeObject.title]: true}))

            }

        }

    }, [location.pathname])

    return (
        <React.Fragment>
            <Drawer elevation={0} variant="temporary" open={drawerOpen} onClose={handleDrawerClose} sx={{width: DRAWER_WIDTH, flexShrink: 0, ['& .MuiDrawer-paper']: { width: DRAWER_WIDTH, boxSizing: 'border-box', padding: 0, background: theme.palette.background.paper, borderTopRightRadius: theme.shape.borderRadius * 4, borderBottomRightRadius: theme.shape.borderRadius * 4}, overflowY: 'auto'}}>
                <Toolbar sx={{borderBottom: `1px solid ${theme.palette.divider}`, display: 'flex', justifyContent: 'center'}}>
                    {
                        theme.palette.mode === 'light' ? <LogoFullHorizontal style={{height: '2.5em'}} /> : <SvgLogoFullHorizontalWhite style={{height: '2.5em'}} />
                    }
                </Toolbar>
                <Box sx={{overflowX: 'hidden', flexGrow:1}}>
                    <List dense sx={{width: DRAWER_WIDTH, mt: 1, px: 1.25}}>
                        {
                            sideNavRoutes.filter(route => route.sideNav).filter(route => route.roles.some(e => userState.role.includes(e))).map((route) => {
                                if(!route.nested){
                                    if(!route.header) {
                                        return (
                                            <ListItemLink activeRouteId={activeRouteId} routeId={route.routeId} nested={false} nestingLevel={0} primary={route.title} to={route.link} key={route.routeId} external={route.external} />
                                        )
                                    } else {
                                        return (
                                            <RouteHeader key={route.routeId} nestingLevel={0} {...route} />
                                        )
                                    }
                                } else {
                                    return (
                                        <NestedRoute activeRouteId={activeRouteId} key={route.routeId} nestingLevel={0} {...route} />
                                    )
                                }
                            })
                        }
                    </List>
                </Box>
            </Drawer>
        </React.Fragment>
    )

}

export default SideNav