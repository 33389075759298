import * as React from 'react'

const ComputePageContainer = React.lazy(() => import('./compute/PageContainer'))
const S3PageContainer = React.lazy(() => import('./s3/PageContainer'))
const RDSPageContainer = React.lazy(() => import('./rds/PageContainer'))
const EgressPageContainer = React.lazy(() => import('./egress/PageContainer'))
const AnalyticsPageContainer = React.lazy(() => import('./analytics/PageContainer'))
const InventoryPageContainer = React.lazy(() => import('./inventory/PageContainer'))
const LambdaPageContainer = React.lazy(() => import('./lambda/PageContainer'))
const CloudwatchPageContainer = React.lazy(() => import('./cloudwatch/PageContainer'))

export {
    ComputePageContainer,
    S3PageContainer,
    RDSPageContainer,
    EgressPageContainer,
    AnalyticsPageContainer,
    InventoryPageContainer,
    LambdaPageContainer,
    CloudwatchPageContainer
}