import * as React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Box, CircularProgress, CssBaseline, Grid, ThemeProvider, createTheme } from '@mui/material'
import PublicRoute from './components/common/PublicRoute'
import { useRecoilState } from 'recoil'
import PrivateRoute from './components/common/PrivateRoute'
import * as Pages from './pages'
import { LoginInitializationState } from './recoil/user/Atom'
import LoginInitializationPage from './components/common/LoginInitializationPage'
import { useUserStateCheck, useUserAppToken} from './hooks/common/user'
import ROUTES from './components/common/Routes'
import MuiThemeConfig from './components/common/MuiThemeConfig'
import useAppTheme from './hooks/common/theme/useAppTheme'

const flattenRoutesList = (routes) => {

  const flatList = []

  const ScanData = (data) => {
    
    for(let i=0;i<data.length;i++){

      if(data[i].nested){
        const { nestedRoutes, ...route } = data[i]
        flatList.push(route)
        ScanData(nestedRoutes)
      } else{
        flatList.push(data[i])
      }

    }

  }

  ScanData(routes)

  return flatList

}

const App = (props) => {


  //const appTheme = React.useMemo(() => createTheme(MuiThemeConfig), [])
  const { getThemeConfig } = useAppTheme()
  const appTheme = createTheme(getThemeConfig())

  const [loginInitialization, setLoginInitialization] = useRecoilState(LoginInitializationState)
  const { verifyState } = useUserStateCheck()
  const { userTokenExist, renewToken } = useUserAppToken()
  let navigate = useNavigate()

  const routeList = flattenRoutesList(ROUTES).filter(route => !route.external)

  React.useEffect(() => {

    /**
     * 1. Check if the JWT token exists for user
     * 2. If the JWT token exists, if required renew the JWT token and check if the user state is present in recoil
     * 3. If the user state is not present in recoil, then initialize the state
     * 
     * If no JWT token is available, then re-direct to the login page
     */
    const verifyUserLoggedInStatus = async () => {
      
      if(userTokenExist()) {

        renewToken()

        if(!verifyState()) {

          setLoginInitialization(true)

        }

      } else {

        navigate('/', { replace: true })

      }

    }

    verifyUserLoggedInStatus()

  },[])


  return (
    <React.Fragment>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <React.Suspense fallback={
          <Box sx={{minHeight: '100vh', minWidth: '100vw', display: 'flex', position:'absolute'}}>
            <Grid container alignItems="center" justifyContent="center" sx={{display: 'flex'}} spacing={2}>
              <Grid item xs={1}>
                <CircularProgress size={30} />
              </Grid>
            </Grid>
          </Box>
        }>
          {
            loginInitialization ? (
              <LoginInitializationPage />
            ) : (
              <Routes>
                {
                  routeList.filter(route => route.routing).map((route) => (
                    <Route key={route.routeId} path={route.link} exact element={route.authenticated ? <PrivateRoute {...route} /> : <PublicRoute {...route} />} />
                  ))
                }
                <Route path={'*'} element={<Pages.NotFoundPageContainer />} />
              </Routes>
            )
          }
        </React.Suspense>
      </ThemeProvider>
    </React.Fragment>
  )

}

export default App