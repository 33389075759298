import * as React from 'react'
import { useRecoilState } from 'recoil'
import { AppThemeState } from '../../../recoil/theme/Atom'
import { deepOrange, red } from '@mui/material/colors'

const config = {
    palette: {
        primary: {
            main: "#ff8001",
        },
        secondary: {
            main: deepOrange['A200'],
        },
        error: {
            light: red['A100'],
            main: red['A400'],
            dark: red['A700']
        },
        background: {
            paper: '#fff',
            default: '#ffffff',
            level1: '#fff'
        },
        divider: "rgba(0,0,0,0.12)",
        text: {
            primary: "rgba(0,0,0,0,87)",
            secondary: "rgba(0,0,0,0.6)",
            disabled: "rgba(0,0,0,0.38)"
        },
        mode: 'light',
        contrastThreshold: 3
    },
    typography: {
        htmlFontSize: 16
    },
    spacing: 4,
    components: {
        MuiTypography: {
            defaultProps: {
            color: 'inherit'
        }
      },
      MuiCircularProgress: {
        defaultProps: {
          size: 24,
          color: 'primary',
          disableShrink: true
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'filled',
          margin: 'dense',
          color: 'primary',
          fullWidth: true,
          size: 'small'
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'filled',
          size: 'small',
          margin: 'dense',
          color: 'primary',
          fullWidth: true
        }
      },
      MuiList: {
        defaultProps: {
          dense: true
        }
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true,
          disableGutters: true
        },
        styleOverrides: {
          root: {
            padding: '0px 8px'
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant:'contained',
          size: 'small',
          color: 'primary',
          disableElevation: true
        },
        styleOverrides: {
          root: {
            fontWeight: 600
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiButtonGroup: {
        defaultProps: {
          size: 'small',
          variant: 'contained',
          disableElevation: true
        }
      },
      MuiCheckbox: {
        defaultProps: {
          size: 'small',
          margin: 'dense'
        }
      },
      MuiFab: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
          fullWidth: true,
          variant: 'filled'
        }
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
          color: 'error'
        }
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
          color: 'primary'
        }
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense'
        }
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense'
        }
      },
      MuiRadio: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiSwitch: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiSkeleton: {
        defaultProps: {
          variant: 'rect',
          animation: 'wave'
        }
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(15px)'
          }
        }
      }
    }
}

const useAppTheme = () => {

    const [appTheme, setAppTheme] = useRecoilState(AppThemeState)
    const [baseTheme, setBaseTheme] = React.useState(config)
    const [themeConfig, setThemeConfig] = React.useState(config)

    React.useEffect(() => {

      if(window.localStorage.getItem('appTheme')) {

        setAppTheme(window.localStorage.getItem('appTheme'))

      } else {

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        if(mediaQuery.matches === false) {

          setAppTheme('light')

        } else {

          setAppTheme('dark')

        }

      }

    }, [])

    React.useEffect(() => {

        if(appTheme === 'light') {

            setThemeConfig({
                ...themeConfig,
                palette: {
                    primary: {
                        main: "#ff8001",
                    },
                    secondary: {
                        main: deepOrange['A200'],
                    },
                    error: {
                        light: red['A100'],
                        main: red['A400'],
                        dark: red['A700']
                    },
                    background: {
                        paper: '#fff',
                        default: '#ffffff',
                        level1: '#fff'
                    },
                    divider: "rgba(0,0,0,0.12)",
                    text: {
                        primary: "rgba(0,0,0,0,87)",
                        secondary: "rgba(0,0,0,0.6)",
                        disabled: "rgba(0,0,0,0.38)"
                    },
                    mode: 'light',
                    contrastThreshold: 3
                }
            })

        } else if (appTheme === 'dark') {

            setThemeConfig({
                ...themeConfig,
                palette: {
                    primary: {
                        main: "#ff8001",
                    },
                    secondary: {
                        main: deepOrange['A200'],
                    },
                    error: {
                        light: red['A100'],
                        main: red['A400'],
                        dark: red['A700']
                    },
                    background: {
                        paper: '#000914',
                        default: '#000914',
                        level1: '#000914'
                    },
                    divider: "rgba(180,180,180,0.15)",
                    text: {
                        primary: "rgba(255,255,255,1)",
                        secondary: "rgba(255,255,255,0.7)",
                        disabled: "rgba(255,255,255,0.5)"
                    },
                    mode: 'dark',
                    contrastThreshold: 3
                }
            })

        }

    },[appTheme])

    const toggleTheme = () => {

        const currentTheme = appTheme
        const newTheme = currentTheme === 'light' ? 'dark' : 'light'

        window.localStorage.setItem('appTheme', newTheme)
        setAppTheme(newTheme)

    }

    const getCurrentTheme = () => {

        return appTheme

    }

    const getThemeConfig = () => {

        return themeConfig

    }

    const getBaseTheme = () => {

      return baseTheme

    }

    return {
        toggleTheme,
        getCurrentTheme,
        getThemeConfig,
        getBaseTheme
    }

}

export default useAppTheme